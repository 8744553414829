import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import Logo196 from '../images/196-Logo.png';
import yellowMist from '../video/196-web-bg.mp4';
import drinkwareLogo from '../images/drinkaware.png';
import fb from '../images/facebook.png';
import insta from '../images/instagram.png';
import ytube from '../images/youtube.png';


import '../App.css';

const Contact = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        
    <div className="bg-[#282728] h-[100%]">
         {/* Navigation bar////////////////////////////////////////////////////////////////////////////////// */}
         <div className='mx-auto max-w-[1440px] x904:h-[128px] absolute w-full z-10 left-1/2 translate-x-[-50%] flex items-center justify-between'>
              {/* Reload page --------------*/}
              <Link to="/" className='cursor-pointer'>
                  <img src={Logo196} className=" cursor-pointer z-20 x904:mt-6 sms:mt-6 sm:mt-6 mob2:mt-6 xs:mt-4 x904:ml-12 xs:ml-[5%]  x904:w-[125px]  sms:w-[180px] sm:w-[145px] mob2:w-[100px]  xs:w-[80px]  "  alt="logo" />
              </Link>

              {/* Burger SVG (conditionally rendered based on screen width) */}
              <svg onClick={toggleMenu} className=" z-20 x904:hidden ml-4 sms:h-16 sms:w-16 sm:w-14 sm:h-14 mob2:w-10 mob2:h-10 xs:w-8 xs:h-8 mr-[5%]" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path  className={`transition-transform  ${isMenuOpen ? ' -rotate-45 translate-y-2 -translate-x-[0.6rem] stroke-white' : ''}`} d="M4 18L20 18" stroke="#000000" strokeWidth="1" strokeLinecap="round"/>
                  <path className={`transition-transform ${isMenuOpen ? 'opacity-0 ' : ''}`}  d="M4 12L20 12" stroke="#000000" strokeWidth="1" strokeLinecap="round"/>
                  <path className={`transition-transform ${isMenuOpen ? 'rotate-45  translate-x-[0.45rem] -translate-y-[0.02rem] stroke-white' : ''}`} d="M4 6L20 6" stroke="#000000" strokeWidth="1" strokeLinecap="round"/>
              </svg>

              {/* DESKTOP NAV LINKS------------------------------------------------------------ */}
              <div className=' flex-row space-x-8 text-[#1d1d1b] font-barlowBold font-extrabold text-[22px] mr-10 x904:flex xs:hidden'>
              <Link to='/#about-us' > <p  className=' cursor-pointer'>ABOUT US</p></Link>
              <Link to='/#flavours' > <p  className=' cursor-pointer'>FLAVOURS</p></Link>
              <Link to='/#where-to-buy' > <p   className=' cursor-pointer'>WHERE TO BUY</p></Link>
              <Link to='/Contact' className='cursor-pointer'> <p   className=' cursor-pointer'>CONTACT US</p></Link>
              </div>

                {/* Overlay Mobile-------------------------------------------------------------- */}
                <div className={`fixed top-0 left-0 w-full h-[100vh] bg-black  z-10 xs:flex justify-center items-center text-center x904:hidden transition-transform duration-[400ms] ease-in  ${isMenuOpen ? 'translate-y-0' : '-translate-y-full'} `}>
                    {/* Navigation links */}
                    <div className="flex flex-col space-y-4 text-white font-barlowBold font-extrabold text-2xl">
                    <Link to='/#about-us'> <p  className='cursor-pointer'>ABOUT US</p></Link> 
                    <Link to='/#flavours' > <p  className=' cursor-pointer'>FLAVOURS</p></Link>
                    <Link to='/#where-to-buy'> <p   className='cursor-pointer'>WHERE TO BUY</p></Link> 
                      <p onClick={toggleMenu}  className='cursor-pointer'>CONTACT US</p>
                    </div>
                </div>
            </div>
            {/* Navigation bar closed ///////////////////////////////////////////////////////////////////////////////*/}
       
      <div className='bg-[#282728] h-full text-center'>
   

        <div className='bg-[#fedd00] relative overflow-hidden text-center '>
            <video  playsInline autoPlay loop muted className=" absolute w-full h-full object-cover opacity-80 "> {/* !!VIDEO FOR THE WHERE TO BUY SECTION NEEDS TO BE PLACED HERE TO WORK!!! */}
              <source src={yellowMist} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
           
            {/* PLACE Form here!----------------------------------------------------------- */}

                    {/* <iframe class="w-full  xs:h-[1650px]  mobMin:h-[1550px]  mob2:h-[1450px] md:h-[1238px] x904:h-[1150px] top-[150px] absolute  overflow-hidden" title="Contact Form" src="https://staging-forms.suntory.cloud/contact/?lang=en-GB&amp;source=-196&amp;uuid=cdd18e949796113a105a22048b2c2c11"  frameborder="0"></iframe> */}

                    {/* <iframe className="w-full h-full top-[150px] relative  overflow-hidden xs:h-[3051px]  mobMin:h-[2920px]  mob2:h-[2790px] md2:h-[2790px] md:h-[2045px] tablet:h-[2025px] x904:h-[2025px] " title="Contact Form" src="https://staging-forms.suntory.cloud/contact/?lang=en-GB&amp;source=-196&amp;uuid=cdd18e949796113a105a22048b2c2c11"  frameBorder="0"></iframe> */}
                    <iframe className="w-full h-full top-[150px] relative  overflow-hidden xs:h-[3200px]  mobMin:h-[3100px]  mob2:h-[2990px] md2:h-[2990px] md:h-[2175px] tablet:h-[2175px] x904:h-[2175px] " title="Contact Form" src="https://forms.suntory.cloud/contact/?lang=en-GB&source=-196&uuid=cdd18e949796113a105a22048b2c2c11"  frameBorder="0"></iframe>
            {/* Form closed ----------------------------------------------------------------------*/}
         
        </div>
        {/* FOOTER SECTION------------------------------------------------------------------- */}
        <div  className='   relative mx-auto w-full bg-[#1d1d1b] md:py-[50px] sms:py-[50px] xs:py-[40px]   '>
            <p className="relative  font-barlowBold font-extrabold x904:text-[21px] sms:text-[21px] sm:text-[20px] mob2:text-[19px]  xs:text-[18px] leading-tight text-[#fedd00]">
              © 2024 SUNTORY -196 
            </p>
            <br/>
            <p className="relative md:mt-[0px] sms:mt-[0px] xs:mt-[0px] font-barlowBold font-light x904:text-[16px] sms:text-[16px] sm:text-[15px] mob2:text-[14px]  xs:text-[13px] leading-tight text-[#ffffff] xs:mx-auto min-[1220px]:w-[467px] x904:w-[467px] sms:w-[467px] sm:w-[467px] mob2:w-[384px] xs:w-[300px]">
            {/* -196 Vodka Soda. Made with Shochu, Vodka and Soda, 6% Alc./Vol.<br/> */}
            Made in EU. Ingredients of EU and Non-EU origin. Produced under the authority of Suntory Spirits Ltd. Distributed by Lucozade Ribena Suntory Ltd. -196 Vodka Soda. Made with Shochu, Vodka and Soda, 6% Alc./Vol.<br/>
            </p>
            <div className="flex items-center x902:mx-0 xs:mx-auto min-[1220px]:w-[192px] x904:w-[192px] sms:w-[192px] sm:w-[192px] mob2:w-[192px] xs:w-[192px] ">
                <span className="relative pt-[6px] font-barlowBold font-normal x904:text-[20px] sms:text-[20px] sm:text-[20px] mob2:text-[20px] xs:text-[20px] leading-none text-[#ffffff]">
                    <b>18+</b>&nbsp;
                </span>
                <img src={drinkwareLogo} className="mt-2 relative min-[1220px]:w-[164px] x904:w-[164px] sms:w-[164px] sm:w-[164px] mob2:w-[164px] xs:w-[160px]" alt="drinkware-Logo" />
            </div>
            <div className="flex justify-center space-x-5 mt-10 h-[42px] xs:mx-auto w-[184px]">
                <a href="https://www.facebook.com/minus196uk?utm_source=website&utm_medium=social-icons&utm_campaign=minus196" target="_blank" rel="noopener noreferrer">
                    <img src={fb} className="relative h-full" alt="facebook" />
                </a>
                <a href="https://www.instagram.com/minus196uk/?utm_source=website&utm_medium=social-icons&utm_campaign=minus196" target="_blank" rel="noopener noreferrer">
                    <img src={insta} className="relative h-full" alt="instagram" />
                </a>
                <a href="https://www.youtube.com/@minus196uk?utm_source=website&utm_medium=social-icons&utm_campaign=minus196" target="_blank" rel="noopener noreferrer">
                    <img src={ytube} className="relative h-full" alt="youtube" />
                </a>
            </div>

        </div>
        {/* FOOTER SECTION END------------------------------------------------------------------- */}
      
   
    
      </div>
    </div>
    );
}
export default Contact;