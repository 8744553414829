import React, { useRef, useEffect, useState } from 'react';

const YouTubeVideo = ({ videoId, width, height, thumbnail }) => {
  const playerRef = useRef(null);
  const playerReady = useRef(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const onPlayerReady = () => {
    playerReady.current = true;
  };

  const onPlayerStateChange = (event) => {
    const { data } = event;
    if (data === window.YT.PlayerState.ENDED) {
      setIsPlaying(false);
      playerRef.current.player.stopVideo();
    }
  };

  useEffect(() => {
    const initPlayer = () => {
      if (playerRef.current && window.YT) {
        playerRef.current.player = new window.YT.Player(playerRef.current, {
          height: height || 'auto',
          width: width || 'auto',
          playerVars: {
            modestbranding: 1, // This removes the YouTube logo from the control bar
            rel: 0, // This prevents showing related videos at the end
            showinfo: 0, // This hides the video title and uploader (Deprecated in API v3)
            iv_load_policy: 3, // This hides video annotations
            disablekb: 1, // This disables keyboard controls
            playsinline: 1, // Plays videos inline on iOS
            fs: 0, // Disables fullscreen button
          },
          videoId: videoId,
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });
      }
    };

    if (!window.YT) {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      window.onYouTubeIframeAPIReady = initPlayer;
    } else {
      initPlayer();
    }

    const currentPlayerRef = playerRef.current;

    return () => {
      if (currentPlayerRef && currentPlayerRef.player) {
        currentPlayerRef.player.destroy();
      }
    };
  }, [height, videoId, width]);

  const playVideo = () => {
    if (playerReady.current) {
      setIsPlaying(true);
      playerRef.current.player.playVideo();
    }
  };

  return (
    <div className='flex justify-center w-full h-full'>
      {!isPlaying && (
        <img
          src={thumbnail}
          alt="Thumbnail"
          onClick={playVideo}
          style={{ cursor: 'pointer' }}
        />
      )}
      <div style={{ display: isPlaying ? 'block' : 'none', width: '100%', height: '100%' }}>
        <div ref={playerRef} style={{ width: '100%', height: '100%' }}></div>
      </div>
    </div>
  );
};

export default YouTubeVideo;
