
import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import './index.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import Promotion from './pages/Promotion'; // Import the Promotion component
import British from './pages/British'; // Import the British component

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/uploads/Text-To-Win-Terms" element={<Promotion />} /> {/* Add the Promotion route */}
        <Route path="/uploads/British-Memes-Comp-Terms" element={<British />} /> {/* Add the British route */}
      </Routes>
    </BrowserRouter>
  );
}

const rootElement = document.getElementById('root');
const root = rootElement ? createRoot(rootElement) : null;

if (root) {
  root.render(<App />);
}
