import { useState, useEffect } from 'react';

function useDeviceDetect() {
  const [isAndroid, setIsAndroid] = useState(false);
  const [isChrome, setIsChrome] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsAndroid(userAgent.indexOf('android') > -1);
    setIsChrome(userAgent.indexOf('chrome') > -1);
  }, []);

  return [isAndroid, isChrome];
}

export default useDeviceDetect;
